import React, { Component } from 'react';
import './Scenario2.css';
import Scenario2TwoImg from '../../img/Scenario2Two.jpg';
import Scenario2Three from './Scenario2Three';




class Scenario2Two extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            show: false
        };
        this.howItWorks = React.createRef()
    }

    handleClick() {
        this.setState({
            disabled: false
        })
    }

    toggleDiv = () => {
        const { show } = this.state;
        this.setState({
            show: true
        })
    }

    scrollTohowItWorks = () => window.scroll({
        top: this.howItWorks.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    });

    render() {
        const { disabled, show } = this.state;
        return (
            <div className='Scenario1TwoWrap' >
                <div className='Scenario1TwoWraper'>
                    <img src={Scenario2TwoImg} alt='Сценарий 1' />
                    <div className='Scenario1TwoRightBlock'>
                        <div className='Scenario1TwoRightTitle'>Сіздің ойыңызша осы жағдайда инспектор қандай құқықтарды бұзып отыр?</div>
                        <div className='ScenarioAllAnons'>Бірнеше дұрыс жауаптар мүмкін</div>
                        <div className='Scenario1TwoRightCheckWrap'>
                            <label className="Scenario1TwoRightCheck" >Күш қолдану ұстанымдарын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Жергілікті  халық құқықтарын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Соттың істі әділ қарау құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Өмір сүру құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Бостандық пен жеке өмірге қол сұқпау құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Жәбірлеуден қорғау құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Тұтқын құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                        </div>
                    </div>
                </div>
                <button disabled={this.state.disabled} onClick={this.toggleDiv} ><div onClick={() => this.scrollTohowItWorks()}>Дұрыс жауабын қараңыз</div></button>
                <div className="contentAppear" ref={this.howItWorks}>{this.state.show && <Scenario2Three />}</div>
            </div>
        )
    }
}

export default Scenario2Two;