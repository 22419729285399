import React, { Component } from 'react';
import Scenario4Three from './Scenario4Three';
import Scenario4TwoImg from '../../img/Scenario4Two.jpg';



class Scenario4Two extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            show: false
        };
        this.howItWorks = React.createRef()
    }

    handleClick() {
        this.setState({
            disabled: false
        })
    }

    toggleDiv = () => {
        const { show } = this.state;
        this.setState({
            show: true
        })
    }

    scrollTohowItWorks = () => window.scroll({
        top: this.howItWorks.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    });

    render() {
        return (
            <div className='Scenario1TwoWrap' >
                <div className='Scenario1TwoWraper'>
                    <img src={Scenario4TwoImg} alt='Сценарий 3' />
                    <div className='Scenario1TwoRightBlock'>
                        <div className='Scenario1TwoRightTitle'>Сіздің ойыңызша инспектор осы жағдайда қандай құқықты бұзып отыр?</div>
                        <div className='ScenarioAllAnons'>Бірнеше дұрыс жауаптар мүмкін</div>
                        <div className='Scenario1TwoRightCheckWrap'>
                            <label className="Scenario1TwoRightCheck" >Тұтқындалушылар құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Өмір сүру құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Азаптаудан азат болу құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Еркіндік құқығы мен жеке өміріне қол сұқпау құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Күш қағидаларын қолдану<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Әділ сот талқылауы құқығы<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                            <label className="Scenario1TwoRightCheck" >Жергілікті халық құқығын<input type="checkbox" onChange={() => this.handleClick()} /><span className="checkmark"></span></label>
                        </div>
                    </div>
                </div>
                <button disabled={this.state.disabled} onClick={this.toggleDiv} ><div onClick={() => this.scrollTohowItWorks()}>Дұрыс жауабын қараңыз</div></button>
                <div className="contentAppear" ref={this.howItWorks}>{this.state.show && <Scenario4Three />}</div>
            </div>
        );
    }
}

export default Scenario4Two;