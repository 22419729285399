import React from 'react';
import './Scenario2.css';
import ReactPlayer from 'react-player';

const Scenario2Video = () => {
    return (
        <div className='Scenario1VideoWrap'>
            <ReactPlayer url='https://youtu.be/Ng6_WYbrRQ4' controls={true} />
        </div>
    );
}

export default Scenario2Video;