import React from 'react';
import './Scenario1.css';
import Scenario1_header from '../../img/Scenario1_header.jpg';
import Scenario1Video from './Scenario1Video';
import Scenario1Two from './Scenario1Two';
import Scenario1Video2 from './Scenario1Video2';
import Scenario1Video3 from './Scenario1Video3';
import Scenario1Four from './Scenario1Four';
import NavBar from '../Navbar';
import { NavLink } from 'react-router-dom';




const Scenario1 = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className=' container contentWrapper'>
                    <div className='Scenario1Wrapper'>
                        <div className='Scenario1Img'>
                            <img src={Scenario1_header} alt='logo' />
                        </div>
                        <div className='Scenario1Content'>
                            <div className='lessonNumber'>10 сабақтың 2-шісі</div>
                            <h1>Адам құқығы, Сценарий 1</h1>
                            <div className='homeAnons'>
                                <h2>Тінту</h2>
                                <p>Ауылда браконьерлік қару-жарақ табылды. Инспекторлар бригадасы Жергілік  тұрғындардың арасынан нақты күдіктіні іздеп жүр.</p>
                            </div>
                        </div>
                    </div>
                    <Scenario1Video />
                    <Scenario1Two />
                    <Scenario1Video2 />
                    <Scenario1Four />
                    <Scenario1Video3 />
                    <button className='btnNext'><NavLink to='/Scenario2'>Ары қарай</NavLink></button>
                </div>
            </div>
        </div>
    );
}

export default Scenario1;