import React from 'react';
import './App.css';
import Home from './Components/Home/Home';
import Scenario1 from './Components/Scenario1/Scenario1';
import Scenario2 from './Components/Scenario2/Scenario2';
import Scenario3 from './Components/Scenario3/Scenario3';
import Scenario4 from './Components/Scenario4/Scenario4';
import Scenario5 from './Components/Scenario5/Scenario5';
import Codex from './Components/Codex/Codex';
import Eshcu from './Components/Eshcu/Eshcu';
import Test from './Components/Test/Test';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from "./Components/scrollToTop";
import Main from './Components/Main'
import LastScenario from './Components/LastScenario/LastScenario'




const App = () => {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Route path='/' exact component={Main} />
        <Route path='/Home' exact component={Home} />
        <Route path='/Scenario1' exact component={Scenario1} />
        <Route path='/Scenario2' exact component={Scenario2} />
        <Route path='/Scenario3' exact component={Scenario3} />
        <Route path='/Scenario4' exact component={Scenario4} />
        <Route path='/Scenario5' exact component={Scenario5} />
        <Route path='/Codex' exact component={Codex} />
        <Route path='/Eshcu' exact component={Eshcu} />
        <Route path='/Test' exact component={Test} />
        <Route path='/LastScenario' exact component={LastScenario} />
      </Router>
    </div>

  );
}

export default App;