import React from 'react';
import './Scenario2.css';
import ReactPlayer from 'react-player';

const Scenario2Video4 = () => {
    return (
        <div className='Scenario1VideoWrap Scenario1Video3'>
            <ReactPlayer url='https://youtu.be/q2h2Nkmx2R8' controls={true} />
        </div>
    );
}

export default Scenario2Video4;