import React from 'react';
import './Scenario2.css';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario2Five = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Әділ сот талқысына құқылы.</div>
            <div className='Scenario1FourAnons'>10-бап. Адам құқықтарының жалпыға бірдей декларациясы. Әрбір адам оның құқығы мен міндеттерін анықтау барысында және оған
             қарсы кез келген қылмыстық айыптау тағылғанда істі әділ әрі бұқаралық түрде тәуелсіз және бейтарап соттың қарауына толықтай тең дәрежеде құқылы. </div>
            <div className='Scenario1FourText'>Бұл сценарийде инспекторлар браконьерге көмек керек кезде саналы түрде араласпауға шешім қабылдайды.
            Әркім, ол қаншалықты жаман болмаса да, әділ сот талқысына лайықты (және өмір сүруге құқылы) әрі оны үнемі тек заңмен бекітілген
            сот жасауы керек, бірақ ешқандай да азамат немесе инспектор емес. Әсіресе браконьермен жеке қалған кезіңізде осы бір маңызды құқықты есіңізде сақтауыңыз керек.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
            <div className='Scenario1FourPs'>Енді қайтадан сценарийді көріп, салыстырып қараңыз.</div>
        </div>
    );
}

export default Scenario2Five;