import React, { Component } from 'react';
import { Quiz } from './Quiz.js';
import './Test.css';
import Navbar from '../Navbar';
import TestBg from '../../img/testBg.png';
import ProgressBar from 'react-animated-progress-bar';
import axios from 'axios';




class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: localStorage.getItem("name"),
            phone: localStorage.getItem("phone"),
            email: localStorage.getItem("email"),
            mailSent: false,
            error: null,
            userAnswer: null,
            currentQuestion: 0,
            options: [],
            quizEnd: false,
            score: 1,
            disabled: true,
            quizStart: false,
            nextBtn: true,
            right: false,
            wrong: false,
            clicked: false
        }
    }


    handleFormSubmit = e => {
        if (this.state.currentQuestion === Quiz.length - 1) {
            this.setState({
                quizEnd: true
            })
        }
        e.preventDefault();
        axios({
            method: 'post',
            url: `https://newweb.kz/Contacts/Mail.php`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.sent
                })
            })
            .catch(error => this.setState({ error: error.message }));
    };


    loadQuiz = () => {
        const { currentQuestion } = this.state;
        this.setState(() => {
            return {
                questions: Quiz[currentQuestion].question,
                options: Quiz[currentQuestion].options,
                answer: Quiz[currentQuestion].answer
            }
        })
    }

    componentDidMount() {
        this.loadQuiz();
    }

    nextQuestionHandler = () => {
        const { userAnswer, answer, score } = this.state;
        this.setState({
            currentQuestion: this.state.currentQuestion + 1,
            wrong: false,
            right: false,
            clicked: false
        })
        if (userAnswer === answer) {
            this.setState({
                score: score + 1
            })
        }
    }
    // Улучшает компонент
    componentDidUpdate(prevProps, prevState) {
        const { currentQuestion } = this.state;
        if (this.state.currentQuestion !== prevState.currentQuestion) {
            this.setState(() => {
                return {
                    disabled: true,
                    questions: Quiz[currentQuestion].question,
                    options: Quiz[currentQuestion].options,
                    answer: Quiz[currentQuestion].answer,
                    nextBtn: true
                }
            })
        }
    }
    // Проверяем ответ
    checkAnswer = answer => {
        this.setState({
            userAnswer: answer,
            disabled: false
        })
    }

    clickedOption = () => {
        this.setState({

        })
    }
    submitQuestion = () => {
        const { userAnswer, answer, right } = this.state;
        if (userAnswer === answer) {
            this.setState({
                right: true,
                wrong: false,
                disabled: true,
                clicked: true
            })
        } else {
            this.setState({
                wrong: true,
                right: false,
                disabled: true,
                clicked: true
            })
        }
        this.setState({
            nextBtn: false
        })
    }
    // Начинаем тест
    startHandler = () => {
        this.setState({
            quizStart: true
        })
    }
    render() {
        const { questions, options, currentQuestion, userAnswer, quizEnd, quizStart, nextBtn, right, wrong, clicked } = this.state;

        if (quizEnd) {
            return (
                <div>
                    <Navbar />
                    <div className='contentLeftPadding'>
                        <div className='testBackgound'>
                            <div className='container contentWrapper'>
                                <h2>Тест аяқталды!</h2>
                                <h4>Сіз {Quiz.length} сұрақтың ішінен жауап алдыңыз {this.state.score}</h4>
                                <ProgressBar width="500" trackWidth="20" percentage={this.state.score * 6.25}
                                    defColor={{
                                        fair: 'orangered',
                                        good: 'yellow',
                                        excellent: 'green',
                                        poor: 'red',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (quizStart) {
            return (
                <div>
                    <Navbar />
                    <div className='contentLeftPadding'>
                        <div className='testBackgound'>
                            <div className='test-wrap container contentWrapper'>
                                <h2>{questions}</h2>
                                <span>{`Cұрақ ${currentQuestion + 1} / ${Quiz.length}`}</span>
                                {options.map(option => (
                                    <p key={options.id}
                                        className={`optionMy ${userAnswer === option ? "selected" : null}`}
                                        onClick={clicked === true ? null : () => this.checkAnswer(option)}>
                                        {option}
                                    </p>
                                ))}
                                <button disabled={this.state.disabled} onClick={this.submitQuestion}>Жіберу</button>
                                <div className={`rightTest ${right === true ? "displayAnswer" : "dontDisplayAnswer"}`}>{Quiz[currentQuestion].right}</div>
                                <div className={`wrongTest ${wrong === true ? "displayAnswer" : "dontDisplayAnswer"}`}>{Quiz[currentQuestion].wrong}</div>
                                {currentQuestion < Quiz.length - 1 && <button disabled={this.state.nextBtn} onClick={this.nextQuestionHandler}>Келесі сұрақ</button>}
                                <form action="#" >
                                    {clicked === true && currentQuestion === Quiz.length - 1 ? <button type="submit" disabled={this.state.nextBtn} onClick={e => this.handleFormSubmit(e)}>Толық тест!</button> : null}
                                </form >

                            </div>
                        </div>
                    </div>
                </div >
            )
        }

        return (
            <div>
                <Navbar />
                <div className='contentLeftPadding'>
                    <div className='testBackgound'>
                        <div className='container contentWrapper'>
                            <div className='testWrapper'>
                                <img src={TestBg} alt='Начать тест' />
                                <div className='testWrapperText'>
                                    <h1>Біліміңізді тексеріңіз</h1>
                                    <h3>Әркеттер қабылданған соң және нәтижелері белгілі болған соң, сол әрекеттің нәтижелерін қарастыруыңыз керек.
                                    Егер мәселенің тиімді шешіміне қол жеткізе алмасаңыз, шешім қабылдаушы тұлға әрекетін өзгертуі керек немесе шешім
                                қабылдау үрдісінің басына оралуы керек.</h3>
                                    <h4>Біліміңізді тексеріңіз – тесттен өту үшін 80% және одан көп жинау керек.</h4>
                                    <button onClick={this.startHandler}>Бастау</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Test;