import React from 'react';
import './Home.css';
import { NavLink } from 'react-router-dom';
import HomeLast1 from '../../img/homeLast1.jpg';
import HomeLast2 from '../../img/homeLast2.jpg';
import HomeLast3 from '../../img/homeLast3.jpg';
import HomeLast4 from '../../img/homeLast4.jpg';
import HomeLast5 from '../../img/homeLast5.jpg';

const HomeLast = () => {
    return (
        <div className='container'>
            <div className='HomeLastWrapper'>
                <div className='HomeLastItem'>
                    <img src={HomeLast1} alt='logo' />
                    <div className='HomeLastItemTitle'>Тінту</div>
                    <div className='HomeLastItemText'>Ауылда браконьерлік қару-жарақ табылды. Инспекторлар бригадасы Жергілік  тұрғындардың арасынан нақты күдіктіні іздеп жүр.</div>
                    <button><NavLink to='/Scenario1'>Сценарий 1</NavLink></button>
                </div>
                <div className='HomeLastItem'>
                    <img src={HomeLast2} alt='logo' />
                    <div className='HomeLastItemTitle'>Қуғын</div>
                    <div className='HomeLastItemText'>Инспекторлар екі браконьердің ізіне түсіп, нәтижесінде тұтқынға алады.</div>
                    <button><NavLink to='/Scenario2'>Сценарий 2</NavLink></button>
                </div>
                <div className='HomeLastItem'>
                    <img src={HomeLast3} alt='logo' />
                    <div className='HomeLastItemTitle'>Сотталған адам</div>
                    <div className='HomeLastItemText'>Инспекторлар бригадасы заңға тартылған тіпті адамға қарсы оқ атқан браконьердің ізіне түседі.</div>
                    <button><NavLink to='/Scenario3'>Сценарий 3</NavLink></button>
                </div>
                <div className='HomeLastItem'>
                    <img src={HomeLast4} alt='logo' />
                    <div className='HomeLastItemTitle'>Тыңшы</div>
                    <div className='HomeLastItemText'>Күдікті браконьер қорғалған аймақта аңдылып, тұтқынға алынады. Онда ешқандай браконьерлік
                    қару жоқ, бірақ 4-6 адамнан тұратын патруль инспекторлары аңдиды.</div>
                    <button><NavLink to='/Scenario4'>Сценарий 4</NavLink></button>
                </div>
                <div className='HomeLastItem'>
                    <img src={HomeLast5} alt='logo' />
                    <div className='HomeLastItemTitle'>Жергілікті тұрғындар</div>
                    <div className='HomeLastItemText'>Патруль инспекторлары өздеріне тиесілі аймақта отын жинап жүрген екі әйелді кездестіреді.
                     Әйелдер осы аймақта отын жинауға құқығы бар Жергілікті тұрғындар тобына жатады.</div>
                    <button><NavLink to='/Scenario5'>Сценарий 5</NavLink></button>
                </div>
            </div>
        </div>
    );
}

export default HomeLast;