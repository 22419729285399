import React from 'react';
import './Scenario2.css';
import ReactPlayer from 'react-player';

const Scenario2Video3 = () => {
    return (
        <div className='Scenario1VideoWrap'>
            <ReactPlayer url='https://youtu.be/s9nUCCm1dzY' controls={true} />
        </div>
    );
}

export default Scenario2Video3;