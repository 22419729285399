import React from 'react';
import ReactPlayer from 'react-player';

const Scenario5Video3 = () => {
    return (
        <div className='Scenario1VideoWrap Scenario1Video3'>
            <ReactPlayer url='https://youtu.be/nyEH9Lww_DY' controls={true} />
        </div>
    );
}

export default Scenario5Video3;