import React from 'react';
import './Scenario2.css';
import '../Scenario1/Scenario1.css';
import Scenario2Header from '../../img/Scenario2Header.jpg';
import Scenario2Video from './Scenario2Video';
import Scenario2Video2 from './Scenario2Video2';
import Scenario2Video4 from './Scenario2Video4';
import Scenario2Two from './Scenario2Two';
import Scenario2Four from './Scenario2Four';
import Scenario2Video3 from './Scenario2Video3';
import Scenario2Five from './Scenario2Five';
import NavBar from '../Navbar';
import { NavLink } from 'react-router-dom';



const Scenario2 = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className="container contentWrapper">
                    <div className='Scenario2Wrapper'>
                        <div className='Scenario2Img'>
                            <img src={Scenario2Header} alt='logo' />
                        </div>
                        <div className='Scenario2Content'>
                            <div className='lessonNumber'>10 сабақтың 3-шісі</div>
                            <h1>Адам құқығы, Сценарий 2</h1>
                            <div className='homeAnons'>
                                <h2>Қуғын</h2>
                                <p>Инспекторлар екі браконьердің ізіне түсіп, нәтижесінде тұтқынға алады.</p>
                            </div>
                        </div>
                    </div>
                    <Scenario2Video />
                    <Scenario2Two />
                    <Scenario2Video2 />
                    <Scenario2Four />
                    <Scenario2Video3 />
                    <Scenario2Five />
                    <Scenario2Video4 />
                    <button className='btnNext'><NavLink to='/Scenario3'>Ары қарай</NavLink></button>
                </div>
            </div>
        </div>
    );
}

export default Scenario2;