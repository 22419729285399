import React from 'react';
import './Eshcu.css';
import eshcuFourImg from '../../img/eshcuFourImg.jpg';


const EshcuFour = () => {
    return (
        <div className='EshcuFourWrap'>
            <img src={eshcuFourImg} alt='logo' />
            <div className='EshcuFourText'>
                <h4>Нақты жауабы жоқ.</h4>
                <p>Осындай жағдайлармен жұмыс істей отырып, олардың әртүрлі болатынын түсіндік; сондықтан алдын-ала дұрыс жауабы қандай болатынын айта алмаймыз.
                Алайда, осындай жағдайларда Этикалық шешімдер қабылдау үлгісі (ЭШҚҮ) көмекші құрал бола алады. Осы үлгіден сіз ең дұрыс жауабын табу үшін
                өзіңізге бір қатар сұрақ қоясыз.</p>
            </div>
        </div>
    );
}

export default EshcuFour;