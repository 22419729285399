import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario4Six = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Адамның еркіндік пен қауіпсіздік құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>9-бап. Ешкім жөнсіз тұтқынға, қамауға алынбауы керек немесе қуғынға ұшырамауы керек.</div>
            <div className='Scenario1FourText'>9-баппен қоса Азаматтық және саяси құқықтар туралы халықаралық пактта әрбір адам еркіндікке, жеке басының құқықтарына қол
            сұғылмауына құқылы, сонымен қоса жосықсыз тұтқынға немесе қамауға алынбауы керек екені айтылады. Оның ішіндегі жалғыз ғана ерекшелік – бұл әрекеттер заңда
            жазылған үрдістерге сай негізде жасалған кезде. Тіпті сол кезде ғана бұл құқық  сақталуы керек белгілі бір ережелер жиынтығы болу керек екендігіне кепілдік береді.
            Тұтқынға алынған күннің өзінде барлық адам оған дейнгі болған құқықтарына ие болады.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
            <div className='Scenario1FourPs'>Енді қайтадан сценарийді көріп, салыстырып қараңыз.</div>
        </div>
    );
}

export default Scenario4Six;