import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario3Five = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Адам еркіндігі мен қауіпсіздігі құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>9-бап. Ешкім жөнсіз тұтқынға, қамауға немесе қуғынға ұшырамауы керек.</div>
            <div className='Scenario1FourText'>Бұл дегеніміз, инспектор немесе басқа да құқық қорғау органдарының қызметкері біреуді заңды түрде тұтқынға алған кезде,
            ол бірқатар ережелерге сәйкес әрекет етуі керек. Ең бастысы – тек заңды негізде ғана тұтқындауға болатынын жете түсінуі керек. Тұтқындаудың жалғыз мақсаты –
            тұтқындалушыны шұғыл түрде оны әділ әрі заңды сот талқылауынан кейін соттай алатын сотқа жеткізу болуы керек.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario3Five;