import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario4Four = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Азаптаудан азат болу құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>5-бап. Ешкім азаптауға немесе қатігез, адамгершілікке жатпайтын немесе ар-намысына тиетін қарым-қатынасқа не жазалауға түспеуі керек.</div>
            <div className='Scenario1FourText'>Тұтқындалушыға қатысты тәртіптік шаралар мен шектеулер ешқашан азаптаудан, қатігездіктен, адамгершілікке жатпайтын немесе
            абыройына нұқсан келтіретін қарым-қатынастан немесе жазадан тұрмауы керек. Күш ешқашан жаза ретінде қолданылмауы керек. Мысалы, осы жағдайда қамауға алынған
            адамға денеге қатысты жазалаулар, ішіп-жеуді не ас-суды тыю немесе адамның ар-намысына тиетін немесе ауыртатын шынжыр, үтік және басқа да қинау құралдарын
            қолдануға тыйым салынған.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario4Four;