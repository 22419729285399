import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderBefore from './SliderBefore';
import CodexSlide1 from './CodexSlide1';
import CodexSlide2 from './CodexSlide2';
import CodexSlide3 from './CodexSlide3';
import CodexSlide4 from './CodexSlide4';
import CodexSlide5 from './CodexSlide5';
import CodexSlide6 from './CodexSlide6';
import CodexSlide7 from './CodexSlide7';
import CodexSlide8 from './CodexSlide8';
import { NavLink } from 'react-router-dom';


const SliderView = () => {
    return (
        <div className='codexSliderWrapper'>
            <SliderBefore />
            <div className='container sliderWrapper'>
                <Slider
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    infinite={false}
                    dots={true}
                    arrows={true}
                >
                    <CodexSlide1 />
                    <CodexSlide2 />
                    <CodexSlide3 />
                    <CodexSlide4 />
                    <CodexSlide5 />
                    <CodexSlide6 />
                    <CodexSlide7 />
                    <CodexSlide8 />
                </Slider>
                <button className='btnNext btnNextPad'><NavLink to='/Eshcu'>Ары қарай</NavLink></button>
            </div>
        </div>
    );
}

export default SliderView;