import React from 'react';
import './Scenario1.css';
import Scenario1TwoImg from '../../img/Scenario1Two.jpg';

const Scenario1Three = () => {
    return (
        <div className='Scenario1TwoWrap'>
            <div className='Scenario1TwoWraper'>
                <img src={Scenario1TwoImg} alt='Сценарий 1' />
                <div className='Scenario1TwoRightBlock'>
                    <div className='Scenario1TwoRightTitle'>Сіздің ойыңызша осы жағдайда инспектор қандай құқықты белден басып отыр?123</div>
                    <div className='Scenario1TwoRightAnons'>Бірнеше дұрыс жауаптар мүмкін</div>
                    <div className='Scenario1TwoRightCheckWrap'>
                        <label className="Scenario1TwoRightCheck disabled" >Тұрғылықты халықтың құқтарын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Соттың істі әділ қарау құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Өмір сүру құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Бостандық пен жеке өмірге қол сұқпау құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Жәбірлеуден қорғау құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck bold" >Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығын<input type="checkbox" checked="checked" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Тұтқын құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scenario1Three;