import React from 'react';
import './Eshcu.css';
import eshcuFiveImg from '../../img/eshcuFiveImg.jpg';
import eshcuAngle from '../../img/eshcuAngle.jpg';


const EshcuFive = () => {
    return (
        <div className='EshcuFiveWrap'>
            <div className='EshcuFiveImagesText'>
                <div className='EshcuFiveText'>
                    <p>Егер дұрысын жасағыңыз келмесе, тіпті<br /> этикаға деген ең ұтымды тәсілдердің өзі көмектесе алмайды.</p>
                    <img className='eshcuAngle' src={eshcuAngle} alt='logo' />
                </div>
                <img src={eshcuFiveImg} alt='logo' />
            </div>
            <div className='EshcuFivePs'>
                <strong>Александр Солженицын,</strong><br />
                орыс жазушы, драматург, тарихшы. 1970 жылы әдебиет саласы бойынша<br /> Нобель сыйлығына ие болған, ол Кеңес Үкіметінен 1974 жылы жіберліп,<br /> Ресейге 1994 жылы қайтып оралған.
            </div>
        </div>
    );
}

export default EshcuFive;