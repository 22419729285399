import React from 'react';
import EshcuSliderIcon2 from '../../img/EshcuSliderIcon2.jpg';


const EshcuSlide2 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>2</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>ЕКІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Сәйкес ақпаратты жинаңыз </strong></span><br />
                қандай заңдар қандай дәрежеде бұзылуы мүмкін екені туралы мейлінше көп ақпарат жинауға тырысуыңыз керек.
                </div>
            </div>
            <img src={EshcuSliderIcon2} alt='logo' />
        </div>
    );
}

export default EshcuSlide2;