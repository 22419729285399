import React from 'react';
import EshcuSliderIcon3 from '../../img/EshcuSliderIcon3.jpg';


const EshcuSlide3 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>3</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>ҮШІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Ақпаратты саралаңыз</strong></span><br />
                Ақпаратты жинағаннан кейін жағдайды бағалау үшін бағалау стандартын немесе критерийін қолдануыңыз керек.
                </div>
            </div>
            <img src={EshcuSliderIcon3} alt='logo' />
        </div>
    );
}

export default EshcuSlide3;