import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario3Four = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Азаптаудан азат болу құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>5-бап. Ешкім азаптауға немесе қатігез, адамгершілікке жатпайтын немесе ар-намысына тиетін қарым-қатынасқа не жазалауға түспеуі керек.</div>
            <div className='Scenario1FourText'>Қандай жағдай болмасын, азаптау ақталмайды. Бұл дегеніміз, ең жоғарғы офицердің немесе мемлекеттік органның бұйрығы азаптауға
            себеп бола алмайды. Күдікті, тіпті қамауда болса да, денесі мен рухының саулығын сақтауға құқылы. Тұтқындауға лауазымы жеткілікті рейнджерлер осы құқық туралы жақсы
            хабардар болуы керек және алдыңызда тұрған адамның қылмысының ауырлығына қарамастан осы құқыққа ие екенін есте сақтауы керек және ескертуі керек.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario3Four;