import React from 'react';
import slideImage1 from '../../img/slider1img.jpg';
import slideImageNum1 from '../../img/slideNumber1.png';


const CodexSlide1 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage1} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum1} alt='logo' />
                <div className='slideLeftBlockText'>Қолымнан келгеннің бәрін жасауға тырысамын және өз қателерімнен сабақ аламын.</div>
            </div>
        </div>
    );
}

export default CodexSlide1;