import React from 'react';
import './Eshcu.css';


const EshcuSecond = () => {
    return (
        <div className='contentBorder'>
            <div className='eshcuFirstText'>
                <p>Алайда, кейбір этикалық сұрақтар заңмен де, ішкі ережелермен де реттелмеуі мүмкін. Осылайша ешқандай заң бұзылмаса да, ішкі жан-дүниеңіз
                сізбен келіспейтін жағдайға тап болуыңыз мүмкін. Сондықтан сіз үшін инспектор ретінде адам құқықтары мен адамгершілік құндылықтарды
                    сыйлауды басшылыққа алатын мекемеңіздің этикасы мен құндылықтарын түсіну маңызды. </p>
            </div>
        </div>
    );
}

export default EshcuSecond;