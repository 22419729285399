import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import MainBear from '../img/mainBear.jpg';



export default class Main extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "",
            phone: "",
            email: "",
            fireRedirect: false
        }
    }

    handleFormSubmit = (e) => {
        localStorage.setItem("name", this.state.name);
        localStorage.setItem("phone", this.state.phone);
        localStorage.setItem("email", this.state.email);
        e.preventDefault()
        this.setState({ fireRedirect: true })
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    render() {
        const { from } = this.props.location.state || '/';
        const { fireRedirect } = this.state;
        return (
            <div className='container contentWrapper mainWrapper'>
                <Form onSubmit={this.handleFormSubmit}>
                    <div className='MainContentBlockWrap'>
                        <div className='MainContentLeftBlock'>
                            <h1>Адам құқығы</h1>
                            <div className='MainContentAnons'>
                                Бұл оқу модулі инспекторларға арналған жəне сізге
                                адам құқықтары туралы қажетті білім алуға, сонымен
                                қатар оларды қаншалықты меңгергеніңізді тексеруге
                                мүмкіндік береді. Жұмысты бастау үшін төмендегі
                                өрістерді толтырып, «Жалғастыру» түймесін басыңыз:
                            </div>
                            <Form.Field label='Сіздің атыңыз *' control={Input} required>
                                <Form.Input name="name" value={this.state.name} onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field label='Телефон нөмірі *' control={Input} required>
                                <Form.Input name="phone" value={this.state.phone} onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field label='E-mail' control={Input}  >
                                <Form.Input name="email" value={this.state.email} onChange={this.handleChange} />
                            </Form.Field>
                        </div>
                        <div className='MainContentRightBlock'>
                            <img src={MainBear} alt='logo' />
                            <Button type="submit">ЖАЛҒАСТЫРУ</Button>
                            {fireRedirect && (
                                <Redirect to={from || '/Home'} />
                            )}
                        </div>
                    </div>
                </Form>
                <div className='MainFormAlert'>* Міндетті өрістер</div>
            </div>
        )
    }
}