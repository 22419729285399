import React from 'react';
import './Eshcu.css';


const EshcuThird = () => {
    return (
        <div className='eshcuFirstLeftWrap contentBorder'>
            <div className='eshcuFirstText'>
                <h4>Мысал:</h4>
                <p>Тұтқындау кезінде әріптесіңіз жетістікке тез жету үшін барын салып жатқанын байқайсыз. Ол сізге тізесінің зақымдалғанынан дұрыс жүре
                    алмайтынын айтады да, ешкімге айтпауыңызды өтінеді, өйткені оның мектепке баратын бес баласы бар және ол жұмысынан айрылып қалудан қорқады.</p>
                <p><strong>Енді сізге екі жолдың біреуін таңдау керек: ол өзін де, әріптестерін де қауіпті жағдайға қалдыратынын біле тұра, оған ары қарай тұтқындауға қатысуға
                    жол бересіз бе?Әлде басшыңызға айтып, әріптестеріңіздің сенімін ақтамайсыз ба?</strong></p>
            </div>
        </div>
    );
}

export default EshcuThird;