import React from 'react';
import './Eshcu.css';
import eshcuFirstImg from '../../img/eshcuFirstImg.jpg';


const EshcuFirst = () => {
    return (
        <div className='eshcuFirstLeftWrap contentBorder contentBorderTop'>
            <div className='eshcuFirstText'>
                <p>Осы халықаралық және ұлттық құқықтар аясындағы жұмыс өз міндеттеріңізді заңды тұрғыда әрі дұрыс атқарып жатқаныңызға кепілдік болады.</p>
                <p>Этика моральды борыштар мен міндеттерге, сонымен қатар «жақсы» мен «жаман» арасында айырмашылыққа байланысты. Этикалық және құқықтық тәртіптер заңға,
                        адмгершілік құндылықтарға деген құрметтен, сонымен қатар адам құқығын сыйлаудан құрылады.</p>
            </div>
            <img src={eshcuFirstImg} alt='logo' />
        </div>
    );
}

export default EshcuFirst;