import React from 'react';
import ReactPlayer from 'react-player';

const Scenario3Video5 = () => {
    return (
        <div className='Scenario1VideoWrap Scenario1Video3'>
            <ReactPlayer url='https://youtu.be/0l3pOf0Yxmg' controls={true} />
        </div>
    );
}

export default Scenario3Video5;