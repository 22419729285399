import React from 'react';
import slideImage4 from '../../img/slider4img.jpg';
import slideImageNum4 from '../../img/slideNumber4.png';


const CodexSlide4 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage4} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum4} alt='logo' />
                <div className='slideLeftBlockText'>Маған сеніп тапсырылған, сонымен қатар маған ұсынылған материалдар мен құралдардың дұрыс қолданылуына жауаптымын.</div>
            </div>
        </div>
    );
}

export default CodexSlide4;