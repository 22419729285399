import React from 'react';
import NavBar from '../Navbar';
import Last1 from '../../img/Last1.jpg';
import Last2 from '../../img/Last2.jpg';
import Last3 from '../../img/Last3.jpg';
import Last4 from '../../img/Last4.jpg';
import './LastScenario.css';




const LastScenario = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container contentWrapper'>
                    <div className='LastAnons'>10 сабақтың 10-шысы</div>
                    <h1 className='LastTitle'>Қорытынды, түйіндеме</h1>
                    <div className='LastText'>Адам құқықтары бүкіл әлемде келісілген және Адам құқықтары туралы халықаралық билльде көрсетілген. Сіздің жұмысыңызда Адам құқықтарының жалпыға бірдей декларациясы, сонымен қатар Азаматтық және саяси құқықтар туралы халықаралық пактінің маңызы зор.</div>
                    <div className='LastText'><strong>Ең маңызды құқық</strong><br />Инспектор ретінде сіз үшін ең маңызды құқық болып, әрбір адамның өмір сүруге құқығы, азаптаудан атаз болу құқығы, еркіндік пен жеке басына қол сұғылмау құқығы табылады. Сонымен қатар адамды, жүк салатын орынды, көлігін тінту, сондай-ақ жеке хат алмасуы тек қана заңды мандат болған кезде ғана жүргізілетінін түсіну керек. Заңды түрде тұтқынға алған соң, Тұтқын құқығын сақтау – тұтқындалушыға тұрақ, ас-суын, қажет болған жағдайда медициналық көмекті қамтамасыз етуіңіз керек дегенді білдіреді.</div>
                    <img src={Last1} alt='logo' />
                    <div className='LastText'><strong>Жергіліктітұрғындар</strong><br />Жергілікті тұрғындар қауымы Адам құқықтары туралы халықаралық бильмен қорғалған, сонымен қатар мәдениеті, тілі, аймақтары Жергіліктіхалық құқығы туралы декларациямен қорғалған.</div>
                    <img src={Last2} alt='logo' />
                    <div className='LastText'><strong>Адам құқықтары туралы халықаралық ережелер</strong><br />Енді сіз адам құқықтары саласындағы халықаралық ережелермен танысқан соң, жұмысыңызда қолданылатын аймақтық немесе халықаралық заңдар туралы хабардар екеніңізге көз жеткізген абзал болар еді. Төменнен оқуға ұсынылған бірқатар материалды табасыз, ал көптеген ұлттық заңдарды Интернеттен іздеп табуға болады.Егер күмәндансаңыз, құқықтық мандатыңыз туралы қосымша ақпаратты командиріңізден немесе менеджеріңізден сұраңыз.</div>
                    <img src={Last3} alt='logo' />
                    <div className='LastText'><strong>Инспекторларға қоғамның сенімі</strong><br />Көп жағдайда дәлелденгендей, инспекторларға қоғамның сенімі қоғаммен жақсы қарым-қатынас арқылы күшейеді. Бұл сонымен қатар браконьерлікпен күресу үшін және басқа да сұрақтарды шешуде маңызды құрал болып табылатын хабар алып отыру мен құрметке ие болуға көмектеседі. Кәсіби түрде әрекет етіп, оған адам құқықтарын сыйлауды қоссаңыз, қоғам мүшелері заңды сыйлап, бұқаралық ақпарат құралдарынан, халықаралық қауымдастықтардан және саяси биліктен қолдау көруі үшін инспектор ретінде қоғамда басқаларға үлгі боласыз.</div>
                    <img src={Last4} alt='logo' />
                    <div className='LastTextStrong'><strong>Егер міндеттерін атқару барысында жолдастарыңыздың біреуі адам құқығын бұзғаны немесе браконьерлерден пара талап еткені белгілі болса, ол туралы WWFөкіліне хабарлай аласыз. Ақпарат құпия болып қалатынына кепілдік береміз және ақпарат расталған жағдайда WWF Сізге 100 000 теңге төлейді.</strong></div>
                </div>
            </div>
        </div>
    )
}

export default LastScenario;