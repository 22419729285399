import React from 'react';
import EshcuSliderIcon5 from '../../img/EshcuSliderIcon5.jpg';


const EshcuSlide5 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>5</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>БЕСІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Шешім қабылдау</strong></span><br />
                Үшінші кезеңдегі бағалау критерийлеріне сәйкес келетін әрекеттердің балама нұсқаларын табуыңыз керек. Шешім қабылдау үрдісінде қолданылатын барлық
                этикалық теорияларына немесе басқа бағалау критерийлеріне жататын әрекеттер бағытын таңдау керек.
                </div>
            </div>
            <img src={EshcuSliderIcon5} alt='logo' />
        </div>
    );
}

export default EshcuSlide5;