import React from 'react';
import './Eshcu.css';
import EshcuSevenImg from '../../img/EshcuSevenImg.jpg';


const EshcuSeven = () => {
    return (
        <div className='EshcuSevenWrap'>
            <div className='EshcuSevenTextTitle'><strong>Жеті қадамның қорытындысын <br />шығарайық:</strong></div>
            <div className='EshcuSevenTextWrap'>
                <img src={EshcuSevenImg} alt='logo' />
                <div className='EshcuSevenText'>
                    <ul>
                        <li>Басты мәселені анықтау </li>
                        <li>Сәйкес ақпаратты жинау </li>
                        <li>Ақпаратты бағалау </li>
                        <li>Балама жолдарын қарастыру</li>
                        <li>Шешім қабылдау</li>
                        <li>Әрекет ету немесе шешім қабылдау</li>
                        <li>Нәтижелерін қарастыру</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default EshcuSeven;