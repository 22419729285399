import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario3Six = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Күш қолдану қарастырылады.</div>
            <div className='Scenario1FourAnons'>4-бап. Құқықтық тәртіпті қолдау бойынша лауазымды тұлғалардың күш және қаруды қолданудағы негізгі ұстанымдары. Құқық қорғау органдарындағы лауазымды тұлғалар өз 
            міндеттерін атқару барысында күш көрсетпей тұрып және қаруды қолданбай тұрып, мүмкіндігінше күштемейтін құралдарды қолдануы керек.</div>
            <div className='Scenario1FourText'>Құқық қорғау органының қызметкері ретінде күшті тек қажет болған жағдайда әрі барынша қысқа уақыт аралығында тек қана 
            заңды болған жағдайда ғана, сол қажет болған кезде ғана қолдана аласыз. Бұл дегеніңіз, заңды мақсатқа қол жеткізу үшін қажетті ең аз күш қолданасыз деген 
            сөз және үнемі әрекетіңіздің заңды, қажетті екенін және асыра сілтеп кетпегеніңізге көз жеткізіп отыруыңыз керек.  Бұл барлық жағдайларға қатысты, қарулы 
            болсаңыз да, болмасаңыз да. Осылайша, қолданатын күштің мөлшері күдіктінің реакциясымен анықталады. 
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario3Six;