import React from 'react';
import './Home.css';
import HomeImg1 from '../../img/home1.jpg';
import HomeImg2 from '../../img/home2.jpg';
import HomeImg3 from '../../img/home3.jpg';
import HomeImg4 from '../../img/home4.jpg';
import HomeTwo from './HomeTwo';
import HomeVideo from './HomeVideo';
import HomeTabs from './HomeTabs';
import HomeLast from './HomeLast';
import NavBar from '../Navbar';

const Home = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container contentWrapper'>
                    <div className="homeWindowOne">
                        <div className='homeImages'>
                            <img src={HomeImg1} alt='logo' />
                            <img src={HomeImg2} alt='logo' />
                            <img src={HomeImg3} alt='logo' />
                            <img src={HomeImg4} alt='logo' />
                        </div>
                        <div className='homeContent'>
                            <div className='lessonNumber'>10 сабақтың 1-шісі</div>
                            <h1>Адам құқығы, Кіріспе</h1>
                            <div className='homeAnons'>
                                <h2>Бастамас бұрын</h2>
                                <p>
                                    Адам құқығы туралы электронды оқытудың бұл модулінде Монғолиядан шыққан
                                    Ұлан-Баторлық рейнджерлер туралы баяндалады – олар күннің суығына (-35°C) қарамастан,
                                    бүкіл әлемдегі инспекторларды қолдау мақсатында, мүкін болатын жағдайларды көрсетті. Мұнда
                                    келтірілген сценарийлар әлемнің кез келген жерінде қолданылады.
                                </p>
                            </div>
                        </div>
                    </div>
                    <HomeTwo />
                    <HomeVideo />
                    <HomeTabs />
                    <HomeLast />
                </div>
            </div>
        </div>
    );
}

export default Home;