import React from 'react';
import './Scenario2.css';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario2Four = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Өмір сүру құқығыс қарастырылған.</div>
            <div className='Scenario1FourAnons'>3-бап. Адам құқықтарының жалпыға бірдей декларациясы. Әрбір адам өмір сүруге, еркіндікке және жеке басына ешкімнің қол сұқпауына құқылы.</div>
            <div className='Scenario1FourText'>Бұл сценарийде браконьерге көмек қажет болатын, бірақ инспекторлар басқаша шешім қабылдады, өйткені әріптестерінің
            өлімі үшін кек қайтарғылары келген. Тіпті ең қатігез браконьердің өзі өмір сүруге (және әділ сотқа) құқылы. Браконьердің жаны қиналу үшін немесе жарақатынан
            көз жұмуы үшін ешқандай әрекетке бармау – қылмыс және инспекторларды ол үшін сотқа тарту керек.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario2Four;