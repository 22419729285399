import React from 'react';
import slideImage3 from '../../img/slider3img.jpg';
import slideImageNum3 from '../../img/slideNumber3.png';


const CodexSlide3 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage3} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum3} alt='logo' />
                <div className='slideLeftBlockText'>Топ мүшесі ретінде маған әріптестерім ауадай қажет және мен де оларға керекпін.
                Олардың жетістікке жетуіне жауапты екенімді сезінемін және қажет болған жағдайда олардың әркеттерінде нені түзеу керектігін көрсетемін.</div>
            </div>
        </div>
    );
}

export default CodexSlide3;