import React from 'react';
import EshcuSliderIcon1 from '../../img/EshcuSliderIcon1.jpg';


const EshcuSlide1 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>1</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>БІРІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Мәселені анықтау</strong></span><br />
                маңызды этикалық ұстанымдар, заң, мекеме стандарты немесе саясаты бұзылуы мүмкін бе екенін анықтай алатын жағдайда болуыңыз керек.
                </div>
            </div>
            <img src={EshcuSliderIcon1} alt='logo' />
        </div>
    );
}

export default EshcuSlide1;