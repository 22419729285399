import React from 'react';
import slideImage6 from '../../img/slider6img.jpg';
import slideImageNum6 from '../../img/slideNumber6.png';


const CodexSlide6 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage6} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum6} alt='logo' />
                <div className='slideLeftBlockText'>Мен адам құқықтарын сыйлаймын және заң нормаларын ұстанамын. Барлық адамзатқа бірдей қараймын және инспектор ретінде, мұқтаж жандарға көмектесемін.</div>
            </div>
        </div>
    );
}

export default CodexSlide6;