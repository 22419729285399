import React from 'react';
import Scenario4TwoImg from '../../img/Scenario4Two.jpg';

const Scenario2Three = () => {
    return (
        <div className='Scenario1TwoWrap'>
            <div className='Scenario1TwoWraper'>
                <img src={Scenario4TwoImg} alt='Сценарий 1' />
                <div className='Scenario1TwoRightBlock'>
                    <div className='Scenario1TwoRightTitle'>Сіздің ойыңызша инспектор осы жағдайда қандай құқықты бұзып отыр?</div>
                    <div className='Scenario1TwoRightCheckWrap'>
                        <label className="Scenario1TwoRightCheck bold" >Тұтқындалушылар құқығын<input type="checkbox" checked="checked" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Өмір сүру құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck bold" >Азаптаудан азат болу құқығын<input type="checkbox" checked="checked" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck bold" >Еркіндік құқығы мен жеке өміріне қол сұқпау құқығын<input type="checkbox" checked="checked" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Күш қағидаларын қолдану<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Әділ сот талқылауы құқығы<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Жергілікті халық құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scenario2Three;