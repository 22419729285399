import React from 'react';
import EshcuSliderIcon7 from '../../img/EshcuSliderIcon7.jpg';


const EshcuSlide7 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>7</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>ЖЕТІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Әрекеттің нәтижелерін қарастыру</strong></span><br />
                Әркеттер қабылданған соң және нәтижелері белгілі болған соң, сол әрекеттің нәтижелерін қарастыруыңыз керек. Егер мәселенің тиімді
                 шешіміне қол жеткізе алмасаңыз, шешім қабылдаушы тұлға әрекетін өзгертуі керек немесе шешім қабылдау үрдісінің басына оралуы керек.
                </div>
            </div>
            <img src={EshcuSliderIcon7} alt='logo' />
        </div>
    );
}

export default EshcuSlide7;