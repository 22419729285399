import React from 'react';
import sliderHeaderImg from '../../img/sliderManBefore.png';


const SliderBefore = () => {
    return (
        <div className='container'>
            <div className='sliderBeforeWrapper'>
                <img src={sliderHeaderImg} alt='logo' />
                <div className='sliderBeforeTextWrap'>
                    <div className='sliderBeforeTextTitle'>Табиғи саябақтардың иснпекторларына арналған тәртіп кодексі</div>
                    <div className='sliderBeforeText'>Инспектор ретінде менің тобым және мен әлемнің жабайы табиғаты мен оның экожүйесін қорғауға маңызды үлес қосамыз.
                    Сондықтан келесі тәртіп кодексін ұстануға міндеттімін:</div>
                </div>
            </div>
        </div>
    );
}

export default SliderBefore;