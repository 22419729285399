import React from 'react';
import slideImage2 from '../../img/slider2img.jpg';
import slideImageNum2 from '../../img/slideNumber2.png';


const CodexSlide2 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage2} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum2} alt='logo' />
                <div className='slideLeftBlockText'>Инспектор болғаныма мақтанамын және оны әркеттерім мен тәртібімде көрсетуге тырысамын.</div>
            </div>
        </div>
    );
}

export default CodexSlide2;