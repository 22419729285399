import React from 'react';
import './Codex.css';
import codexHeaderImg from '../../img/codexHeader.png';
import SliderView from './SliderView';
import NavBar from '../Navbar';



const Codex = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container codexHeaderWrapper contentWrapper'>
                    <div className='codexLeftBlock'>
                        <img src={codexHeaderImg} alt='logo' />
                    </div>
                    <div className='codexRightBlock'>
                        <div className='codexSabak'>10 сабақтың 7-шісі</div>
                        <h1>Жүріс-тұрыс ережелері</h1>
                        <h2>Жүріс-тұрыс кодексі — бұл әдетте мекемелерде қолданылатын кәсіби тәртіптің стандарттары тізімі.</h2>
                        <h3>
                            <strong>Жүріс-тұрыс кодексінің мысалы</strong><br /><br />
                    Мақсатымыз – мекемелердің тәртіп кодексін тықпалау емес, бірақ кейбір мекемелер үшін кодекс түрлерінің мысалы болса, артық етпес еді.
                    Сондықтан біз өзіңіздің түсіндірмеңізге сәйкес қолдана алатын әмбебап әдеп кодексін (мысалын) қосамыз.
                </h3>
                    </div>
                </div>
                <SliderView />
            </div>
        </div>
    );
}

export default Codex;