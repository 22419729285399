import React from 'react';
import slideImage8 from '../../img/slider8img.jpg';
import slideImageNum8 from '../../img/slideNumber8.png';


const CodexSlide8 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage8} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum8} alt='logo' />
                <div className='slideLeftBlockText'>Маған сеніп тапсырылған билікті орынсыз қолданбаймын, асыра сілтемеймін. Қажет болған жағдайда күш қолданамын, бірақ тек аса қажет болған кезде және тапсырманы орындау үшін ғана. Маған жұмыс барысымен хабарласқандар менің адал әрі шешім қабылдай алатыныма сенуіне болады.</div>
            </div>
        </div>
    );
}

export default CodexSlide8;