import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario5Four = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Әйелдер құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>5-бап. Адам құқықтарының жалпыға бірдей декларациясы. Ешкім жәбір көрмеуі керек немесе қатігездік, адамгершілікке жатпайтын қанау немесе жазалау көрмеуі керек.</div>
            <div className='Scenario1FourText'>Бұл жағдайда инспекторлар әйелдерді сыйламады, олардың ар-насымына тиетіндей қарым-қатынас жасады, әйелдерді кінәлі деген
            сияқты ескертулер жасады. Бұл әрекеттер адам құқығына қайшы. Жергілік тұрғындардың құқығына қатысты жағдайдағы сияқты, барлық адам құқықтары әйелдерге де
            қатысты; бұл – барлық адамдарды сыйлау керек екеніңізді білдіреді.  Адамдарды тінту барысында тінтуші адам, тінтілуші адамның жынысындай болу керек.  Егер әйелді
            әйел тінтетіндей мүмкіндік жоқ болса, онда тінтуге рұқсат алу керек (камераға түсірілген) немесе әйелді әйел тінтетін мүмкіндігі бар жерге апару керек. Не болмаса
            тінтуден бас тарту керек.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario5Four;