import React from 'react';
import './Eshcu.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EshcuSlide1 from './EshcuSlide1';
import EshcuSlide2 from './EshcuSlide2';
import EshcuSlide3 from './EshcuSlide3';
import EshcuSlide4 from './EshcuSlide4';
import EshcuSlide5 from './EshcuSlide5';
import EshcuSlide6 from './EshcuSlide6';
import EshcuSlide7 from './EshcuSlide7';


const EshcuSlider = () => {
    return (
        <div className='container'>
            <div className='EshcuSliderWrap'>
                <Slider
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    infinite={false}
                    dots={true}
                    arrows={true}
                >
                    <EshcuSlide1 />
                    <EshcuSlide2 />
                    <EshcuSlide3 />
                    <EshcuSlide4 />
                    <EshcuSlide5 />
                    <EshcuSlide6 />
                    <EshcuSlide7 />
                </Slider>
            </div>
        </div>
    );
}

export default EshcuSlider;