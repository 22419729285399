import React from 'react';
import EshcuSliderIcon4 from '../../img/EshcuSliderIcon4.jpg';


const EshcuSlide4 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>4</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>ТӨРТІНШІ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Баламасын қарастыру</strong></span><br />
                Төмендегідей мүмкін болатын балама әрекеттердің жиынтығын туындатуыңыз керек: басқа біреудің әрекетіне қарсы әрекеттер, жоғары
                лауазымдының араласуына жүгіну және болып жатқан жағдайдың бағытын өзгерту.
                </div>
            </div>
            <img src={EshcuSliderIcon4} alt='logo' />
        </div>
    );
}

export default EshcuSlide4;