import React from 'react';
import EshcuSliderIcon6 from '../../img/EshcuSliderIcon6.jpg';


const EshcuSlide6 = () => {
    return (
        <div className='EshcuSlideWrapper'>
            <div className='EshcuSlideNum'>6</div>
            <div className='EshcuSlideTextWrap'>
                <div className='EshcuSlideTextTitle'><strong>АЛТЫНШЫ ҚАДАМ</strong></div>
                <div className='EshcuSlideText'><span><strong>Әрекет ету немесе жүзеге асыру</strong></span><br />
                Егер қарастырылған мәселені шынымен шешуге әрекет етсеңіз, онда одан кейінгі шараларды қолдануыңыз керек. Төртінші кезеңде әрекеттің балама нұсқалары анықталған соң
                және бесінші кезеңде тиімді жауап таңдалған соң, әрекет алтыншы кезеңде орындалады.
                </div>
            </div>
            <img src={EshcuSliderIcon6} alt='logo' />
        </div>
    );
}

export default EshcuSlide6;