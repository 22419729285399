import React from 'react';
import './Eshcu.css';


const EshcuSix = () => {
    return (
        <div className='EshcuSixWrap'>
            <div className='EshcuSixTitle'>Этикалық шешімдер<br /> қабылдау үлгісі (ЭШҚҮ)</div>
            <div className='EshcuSixText'><strong>Қанша уақытыңыз қалғанына қарамастан: бұл үлгіні қолдануға болады! ЭШҚҮ нық шешімдер қабылдауға көмектесе алады.</strong></div>
            <div className='EshcuSixAnons'><strong>ЭШҚҮ жеті кезеңнен тұрады.</strong><br /> Шешім қабылдауда келесі қадамдарды қолдана алады:</div>
        </div>
    );
}

export default EshcuSix;