import React from 'react';
import './Home.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HomeTab1 from '../../img/HomeTab1.jpg';
import HomeTab2 from '../../img/HomeTab2.jpg';
import HomeTab3 from '../../img/HomeTab3.jpg';
import HomeTab4 from '../../img/HomeTab4.jpg';
import HomeTab5 from '../../img/HomeTab5.jpg';
import HomeTab6 from '../../img/HomeTab6.jpg';
import HomeTab7 from '../../img/HomeTab7.jpg';
import HomeTab8 from '../../img/HomeTab8.jpg';

const HomeTabs = () => {
    return (
        <div className='container'>
            <div className='HomeTabsWrapper'>
                <Tabs>
                    <div className='HomeTabsContent'>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab1} alt='logo' />
                                <div className='HomeTabTitle'>Бірге өмір сүру</div>
                                <div className='HomeTabText'>Адамдар басқа адамдармен қоса, басқа да тіршілік иелерімен бірге өмір сүреді.
                                Біздің олардан айырмашылығымыз – ойлай алатынымызда. Осы қабілетіміздің арқасында біз, мысалы, әртүрлі
                                көзқарасты түсіне аламыз, құндылықтарымыз бен ұстанымдарымыз бар және біз бір-бірімізбен сөйлесе аламыз.
                                Басты құндылықтардың бірі – барлығымыздың бір дәрежеде туғанымызда және біз барлық адамды сыйлауымыз керек.
                                Адамдарға тән тағы бір маңызды ерекшелік – бір-бірімізбен келісіп, ортақ ереже құрамыз. Бірақ неге бізде ереже бар?
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab2} alt='logo' />
                                <div className='HomeTabTitle'>Шекаралар</div>
                                <div className='HomeTabText'>Ережелердің көмегімен біз бір-бірімізге шекара қоямыз.
                                Біздің бостандығымызды, құқықтарымызды, меншіктерімізді, т.б. қорғайтын шекаралар. Барлығымыз
                                бірге бейбітшілікте өмір сүре алуымыз үшін. Ережелердің көмегімен негізгі құқықтарымыз қорғалған
                                немесе жаңа құқықтар жасалып, бізге беріледі. Алайда, сонымен қоса ол міндеттер мен жауапкершіліктерді жүктейді.
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab3} alt='logo' />
                                <div className='HomeTabTitle'>Ережелер жиынтығы</div>
                                <div className='HomeTabText'>Екі (немесе одан көп) адам бір-бірімен ережелер жиынтығы туралы келісуі мүмкін
                                және сол ережелер тек қана сол екі (немесе одан көп) тұлғаны байланыстырады. Тура сол сияқты екі ел де бір-бірімен
                                нақты бір ережелер туралы келісе алады және ол ережелер тек  сол елдерде тұратын азаматтарды ғана байланыстыра алады.
                                Осы соңғы келісімді конвенция немесе келісімшарт деп атауға болады, ал оған келісетін елдерді тараптар немесе кей
                                жағдайларда мүше мемлекеттер деп те атайды.
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab4} alt='logo' />
                                <div className='HomeTabTitle'>Халықаралық құқық</div>
                                <div className='HomeTabText'>Елдер арасында көптеген конвенция бар және олар халықаралық құқықтың бір бөлігі болып т
                                абылады. Кейбір тақырыптардың маңыздылығы соншалық, барлығына дерлік елдер сол сұрақ бойынша конвенция мүшесі болып табылады.
                                Соның мысалы әрі ең маңызды тақырыбы – Адам құқығы болып табылады.<br /><br />
                                Адам құқығын адамдардың қарым-қатынасын реттейтін стандартты сипаттайтын және бүкіл адамзат иесіне бұл өмірде тең
                                дәрежедегі мүмкіндікті қамтамасыз етеді деп танылған негізгі ұстанымдар деп қарастыруға болады. Әрбір адам өзінің ұлтына,
                                этникалық шығу тегіне, дініне, жынысына немесе басқа да мәртебесіне қарамастан осы құқықтарға ие, сондықтан оларды әмбебап құқықтар
                                деп атайды. Барлық адамдар сияқты біздің де құқықтарымыз бар, біздің де кім екенімізге қарамастан құндылықтарға, бостандыққа
                                және лайықты өмір сүруге құқығымыз бар.
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab5} alt='logo' />
                                <div className='HomeTabTitle'>Адам құқығы туралы халықаралық заң жобасы</div>
                                <div className='HomeTabText'>Осы ұстанымды қолдау мақсатында Біріккен Ұлттар Ұйымы адам құқығы туралы
                                халықаралық заң жобасын қабылдады, ол үш құжаттан тұрады:
                                <br />• Адам құқықтарының жалпыға бірдей декларациясы (АҚЖД, 1948)
                                <br />• Азаматтық және саяси құқықтар туралы халықаралық пакт (АСҚХП, 1966)
                                <br />• Экономикалық, әлеуметтік және мәдени құқықтар туралы  халықаралық пакт (ЭӘМҚХП, 1966)
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab6} alt='logo' />
                                <div className='HomeTabTitle'>Үш негізгі ұстаным</div>
                                <div className='HomeTabText'>Бұл материал инспектордың жұмысымен тікелей байланысты және осы оқу курсында толығырақ талқыланады.
                                Үш негізгі қағидат бар, олардың барлығы Адам құқықтарының жалпыға бірдей декларациясымен қорғалады және құқық қорғау органдарының
                                қызметкері ретінде сіздің этикалық және заңды жүріс-тұрысыңызды қамтамасыз етудің кілті болып табылады:<br />
                                    <br />1. Адамның тұлға ретіндегі құндылықтарын сыйлау.
                                <br />2. Адам құқығын сыйлау және қорғау.
                                <br />3. Заңға бағыну және сыйлау.
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab7} alt='logo' />
                                <div className='HomeTabTitle'>Біріккен Ұлттар Ұйымының құқық қорғау органдары қызметкерлерінің тәртібі кодексі</div>
                                <div className='HomeTabText'>Инспектор ретінде сіз үшін басты құжат - бұл оқу курсында талқыланатын Біріккен Ұлттар Ұйымының Құқық тәртібі кодексі.
                                Адам құқықтары туралы басқа конвенциялар немесе келісімдерге адам және халық құқығы туралы Африка хартиясы кіреді;
                                және адам құқықтары туралы Американдық конвенция; немесе жергілікті халықтардың, әйелдердің, балалардың немесе
                                басқа осал топтардың құқықтарын қосымша қорғауды қамтамасыз ететіндер.
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='HomeTab1'>
                                <img src={HomeTab8} alt='logo' />
                                <div className='HomeTabTitle'>Міндеттер</div>
                                <div className='HomeTabText'>Инспектордың құқықтарының болуы сіз үшін сіздің әрекеттеріңіз немесе басқалардың іс-әрекеттері сіздің жеке
                                өміріңізде немесе жұмысыңыздың бір бөлігінде жасалғанына қарамастан, сіздің әрекеттеріңіз немесе басқалардың іс-әрекеттері кез-келген
                                адамның құқығын бұзбайтындығына кепілдік беруіңізді білдіреді. Кәсіби инспектор ретінде сіз жалпыға бірдей халықаралық келісімдерден бастап,
                                сіздің жұмысыңызға қатысты барлық заңдардан хабардар болуыңыз керек. Бұл өте маңызды, өйткені сізге инспектор лауазымы жүктелді, сіз өте мұқият
                                болуыңыз керек және адам құқығын мұқият құрметтеуіңіз керек. Бұл оқулықта сіз инспектор ретіндегі жұмысыңызда адам құқығының рөлі туралы көбірек
                                білесіз.
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                    <TabList className='HomeTabsLinks'>
                        <Tab>Бірге өмір сүру</Tab>
                        <Tab>Шекаралар</Tab>
                        <Tab>Ережелер жиынтығы</Tab>
                        <Tab>Халықаралық құқық</Tab>
                        <Tab>Адам құқығы туралы халықаралық заң жобасы</Tab>
                        <Tab>Үш негізгі ұстаным</Tab>
                        <Tab>Біріккен Ұлттар Ұйымының құқық қорғау органдары <br /> қызметкерлерінің тәртібі кодексі</Tab>
                        <Tab>Міндеттер</Tab>
                    </TabList>
                </Tabs>
            </div>
        </div>
    );
}

export default HomeTabs;