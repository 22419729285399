import React from 'react';
import './Home.css';
import HomeTwoManOne from '../../img/homeTwoManOne.jpg';
import HomeTwoLogo from '../../img/homeTwoLogo.jpg';
import HomeTwoManTwo from '../../img/homeTwoManTwo.jpg';

const HomeTwo = () => {
    return (
        <div className='homeWindowTwo'>
            <div className='homeWindowTwoLeft'>
                <div className='homeWindowTwoManOne'>
                    <img src={HomeTwoManOne} alt='Кейт Свенсон' />
                    <div className='homeWindowTwoManName'>Кейт Свенсон</div>
                </div>
                <div className='homeWindowTwoManTwo'>
                    <img src={HomeTwoManTwo} alt='Энхджин Оюнбаатар' />
                    <div className='homeWindowTwoManName'>Энхджин Оюнбаатар</div>
                </div>
            </div>
            <div className='homeWindowTwoRight'>
                <div className='homeWindowTwoRightText'>
                    Моңғолияның Қоршаған ортаны қорғау агенттігінің білікті бөлімшесіне бірігіп жұмыс жасағандары үшін алғыс білдіреміз.
                    Сол жерлерде өкіліміз болған Stone Horse Expeditions & Travel-дық Кейт Свенсонға ерекше алғыс. Бұл модуль Жергілік
                    полиция қызметкері әрі қауымдастық мүшесі Энхджин Оюнбаатордың тарапынан көрсетілген қолдау мен WWF NL және WWF UK
                    тарапынан көрсетілген қаржыландырусыз мүмкін болмас еді.
                </div>
                <img src={HomeTwoLogo} alt='Энхджин Оюнбаатар' />
            </div>
        </div>
    );
}

export default HomeTwo;