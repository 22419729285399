import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderImage from '../img/header-bg.jpg';
import HeaderLogo from '../img/wwf-logo.jpg';



const Navbar = () => {
    return (
        <div className='navbarWrapper'>
            <div className="logoWwf">
                <img src={HeaderLogo} alt='logo' />
            </div>
            <img src={HeaderImage} alt='logo' />
            <div className='navbar'>
                <div className='navbarLink'>
                    <NavLink to='/Home'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Кіріспе
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Scenario1'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Сценарий 1
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Scenario2'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Сценарий 2
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Scenario3'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Сценарий 3
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Scenario4'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Сценарий 4
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Scenario5'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                    Адам құқығы Сценарий 5
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Codex'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                        Жүріс-тұрыс кодексі
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Eshcu'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                        ЭШҚҮ
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/Test'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 16" width="16" height="16" className="i" focusable="false"><title>Question box</title><desc>Question mark inside of a square</desc><path d="M14,14 L2,14 L2,2 L14,2 L14,14 Z M15,0 L1,0 C0.4,0 0,0.4 0,1 L0,15 C0,15.6 0.4,16 1,16 L15,16 C15.6,16 16,15.6 16,15 L16,1 C16,0.4 15.6,0 15,0 L15,0 Z"></path><path d="M7.9997,11 C8.5517,11 8.9997,11.448 8.9997,12 C8.9997,12.552 8.5517,13 7.9997,13 C7.4477,13 6.9997,12.552 6.9997,12 C6.9997,11.448 7.4477,11 7.9997,11"></path><path d="M7.0997,5.5 C7.2997,5.2 7.5997,5 7.9997,5 C8.5997,5 8.9997,5.4 8.9997,6 C8.9997,6.3 8.8997,6.4 8.3997,6.7 C7.7997,7.1 6.9997,7.7 6.9997,9 L6.9997,10 L8.9997,10 L8.9997,9 C8.9997,8.8 8.9997,8.7 9.4997,8.4 C10.0997,8 10.9997,7.4 10.9997,6 C10.9997,4.3 9.6997,3 7.9997,3 C6.8997,3 5.8997,3.6 5.3997,4.5 L4.8997,5.4 L6.5997,6.4 L7.0997,5.5 Z"></path></svg></div>
                        Өз біліміңді тексер
                    </NavLink>
                </div>
                <div className='navbarLink'>
                    <NavLink to='/LastScenario'>
                        <div className="lesson-link__icon"><svg viewBox="0 0 16 12" width="16" height="12" className="i" focusable="false"><title>Align left</title><desc>Three vertical lines aligned to the left</desc><path d="M8.4148147,12 L1.05185184,12 C0.471229623,12 0,11.552 0,11 C0,10.448 0.471229623,10 1.05185184,10 L8.4148147,10 C8.99543692,10 9.46666654,10.448 9.46666654,11 C9.46666654,11.552 8.99543692,12 8.4148147,12"></path><path d="M12.2500002,2 L0.816666683,2 C0.365866674,2 0,1.552 0,1 C0,0.448 0.365866674,0 0.816666683,0 L12.2500002,0 C12.7008002,0 13.0666669,0.448 13.0666669,1 C13.0666669,1.552 12.7008002,2 12.2500002,2"></path><path d="M15,7 L1,7 C0.448,7 0,6.552 0,6 C0,5.448 0.448,5 1,5 L15,5 C15.552,5 16,5.448 16,6 C16,6.552 15.552,7 15,7"></path></svg></div>
                        Қорытынды
                    </NavLink>
                </div>
            </div>
        </div >
    );
}

export default Navbar;