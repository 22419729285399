import React from 'react';
import Scenario3Header from '../../img/Scenario3Header.jpg';
import Scenario3Video from './Scenario3Video';
import '../Scenario1/Scenario1.css';
import Scenario3Two from './Scenario3Two';
import Scenario3Four from './Scenario3Four';
import Scenario3Video2 from './Scenario3Video2';
import Scenario3Video3 from './Scenario3Video3';
import Scenario3Five from './Scenario3Five';
import Scenario3Six from './Scenario3Six';
import Scenario3Video5 from './Scenario3Video5';
import Scenario3Seven from './Scenario3Seven';
import Scenario3Video6 from './Scenario3Video6';
import NavBar from '../Navbar';
import { NavLink } from 'react-router-dom';



const Scenario3 = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container contentWrapper'>
                    <div className='Scenario2Wrapper'>
                        <div className='Scenario2Img'>
                            <img src={Scenario3Header} alt='logo' />
                        </div>
                        <div className='Scenario2Content'>
                            <div className='lessonNumber'>10 сабақтың 4-шісі </div>
                            <h1>Адам құқығы, Сценарий 3</h1>
                            <div className='homeAnons'>
                                <h2>Сотталған адам</h2>
                                <p>Инспекторлар бригадасы заңға тартылған тіпті адамға қарсы оқ атқан браконьердің ізіне түседі.</p>
                            </div>
                        </div>
                    </div>
                    <Scenario3Video />
                    <Scenario3Two />
                    <Scenario3Video2 />
                    <Scenario3Four />
                    <Scenario3Video3 />
                    <Scenario3Five />
                    <Scenario3Video3 />
                    <Scenario3Six />
                    <Scenario3Video5 />
                    <Scenario3Seven />
                    <Scenario3Video6 />
                    <button className='btnNext'><NavLink to='/Scenario4'>Ары қарай</NavLink></button>
                </div>
            </div>
        </div>
    );
}

export default Scenario3;