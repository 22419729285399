import React from 'react';
import './Scenario2.css';
import Scenario2TwoImg from '../../img/Scenario2Two.jpg';

const Scenario2Three = () => {
    return (
        <div className='Scenario1TwoWrap'>
            <div className='Scenario1TwoWraper'>
                <img src={Scenario2TwoImg} alt='Сценарий 1' />
                <div className='Scenario1TwoRightBlock'>
                    <div className='Scenario1TwoRightTitle'>Сіздің ойыңызша осы жағдайда инспектор қандай құқықтарды бұзып отыр?</div>
                    <div className='Scenario1TwoRightCheckWrap'>
                        <label className="Scenario1TwoRightCheck disabled" >Күш қолдану ұстанымдарын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Жергілікті  халық құқықтарын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck bold" >Соттың істі әділ қарау құқығын<input type="checkbox" checked="checked" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Өмір сүру құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Бостандық пен жеке өмірге қол сұқпау құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Жәбірлеуден қорғау құқығын<input type="checkbox" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck bold" >Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығын<input type="checkbox" checked="checked" /><span className="checkmark"></span></label>
                        <label className="Scenario1TwoRightCheck disabled" >Тұтқын құқығын<input type="checkbox" disabled="disabled" /><span className="checkmark"></span></label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scenario2Three;