import React from 'react';
import slideImage7 from '../../img/slider7img.jpg';
import slideImageNum7 from '../../img/slideNumber7.png';


const CodexSlide7 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage7} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum7} alt='logo' />
                <div className='slideLeftBlockText'>Маған жүктелген міндеттерді кәсіби тұрғыда атқарамын, тіпті қиын жағдайларда да, 
                парақорлықпен бетпе-бет жолықсам да, тіпті өміріме қауіп төнген жағдайда да.</div>
            </div>
        </div>
    );
}

export default CodexSlide7;