import React from 'react';
import ReactPlayer from 'react-player';

const Scenario4Video3 = () => {
    return (
        <div className='Scenario1VideoWrap Scenario1Video3'>
            <ReactPlayer url='https://youtu.be/dMnlkZeB_Hs' controls={true} />
        </div>
    );
}

export default Scenario4Video3;