import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario4Five = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Тұтқындалушы құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>10-бап. Азаматтық және саяси құқықтар туралы халықаралық пакт. Бас бостандығынан айырылған барлық тұлғаларға адамға тән
            адамгершілік пен қадір-қасиетін құрметтеп қарау керек.</div>
            <div className='Scenario1FourText'>Тұтқын құқығында адамды қамауға алып, бас бостандығынан айырған соң, оған адамға тән адамгершілікпен, қадір-қасиетін
            құрметтеп қарау айқын басымдылықпен айтылады. Оның ішіне тұтқындалушыға жеткілікті мөлшерде демалыс, ас-су қамтамасыз етілуі кіреді, алайда ол бұл сценарийде жоқ.
            Күдіктіге инспекторлардың заңға қайшы қарым-қатынаста болуы заңмен қудаланады.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
        </div>
    );
}

export default Scenario4Five;