import React from 'react';
import './Scenario1.css';
import Scenario1FourAlert from '../../img/alert.jpg';


const Scenario1Four = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Жеке өміріне, тұрағына немесе хат алмасуына еркінен тыс араласу құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>Адам құқықтарының жалпыға бірдей декларациясының 12-бабы. Ешкім оның жеке өміріне, отбасына,
            тұрағына немесе хат алмасуына, сонымен қатар оның ар-намысына, беделіне еркінен тыс қол сұқпауы керек</div>
            <div className='Scenario1FourText'>Бұл құқық инспекторлар үшін өте маңызды, себебі ол адамдарды, жүктерді, көлік құралын немесе үйін,
            сонымен қатар хаттар не хабарламалар сияқты жеке хат алмасуын тінтуге қатысты. Біреудің жеке меншігін тінте отырып, сіз мүмкіндігіңізше
            ұлттық заңнамаңызға сай тінтуге негіз болатын ордерді алуыңыз керек. Заңсыз мандатсыз бұлай тінту жүргізу заңға қайшы келеді. Барлық іздеулер
            заңнамалық ұстанымдарға, қажеттіліктерге сай болуы керек және шектен шықпауы керек.
            <img src={Scenario1FourAlert} alt='logo' />
            </div>
            <div className='Scenario1FourPs'>Енді қайтадан сценарийді көріп, салыстырып қараңыз.</div>
        </div>
    );
}

export default Scenario1Four;