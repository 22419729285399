import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario5Five = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Жергілікті тұрғындар құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>1-бап. Жергілікті халық құқығы туралы БҰҰ декларациясы. Жергілікті халық ұжымдасып немесе жекелей Адам құқытарының жалпыға
            ортақ декларациясы мен Халықаралық адам құқықтарындағы Біріккен Ұлттар Ұйымының Жарғысында мақұлданған барлық адам құқықтары мен негізгі еркіндікті иеленуге
            толықтай құқылы.</div>
            <div className='Scenario1FourText'>Жергілікті тұрғындар қауымдастығын қорғау үшін жергілікті тұрғындар құқықтары туралы БҰҰ декларациясы түріндегі жергілікті
            тұрғындардың қосымша құқықтары бар. (ХЕМ Конвенциясы № 169). Бұл декларацияда физикалық тұрғыдан өмір сүру мен қол сұғылмауға қатысты адамның барлық негізгі құқықтары,
            сонымен қатар өз жерін, тілін, діні мен олардың халық ретінде тіршілік етунің бір бөлігі болып табылатын мәдени мұралардың басқа да элементтерін сақтауға қатысты нақты
            құқықтар кіреді. Бұл мысалда жергілікті тұрғындар тобынан шыққан әйелдер отын мен отынға жатпайтын орман өнімдерін жинауға құқылы. Жергілікті тұрғындармен жұмыс жасау
            барысында олардың халықаралық, сонымен қатар ұлттық құқықтарын білетініңізге және соны үнемі қорғайтыныңызға көз жеткізіңіз.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
            <div className='Scenario1FourPs'>Енді қайтадан сценарийді көріп, салыстырып қараңыз.</div>
        </div>
    );
}

export default Scenario5Five;