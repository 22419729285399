import React from 'react';
import slideImage5 from '../../img/slider5img.jpg';
import slideImageNum5 from '../../img/slideNumber5.png';


const CodexSlide5 = () => {
    return (
        <div className='slideWrapper'>
            <img src={slideImage5} alt='logo' />
            <div className='slideLeftBlock'>
                <img src={slideImageNum5} alt='logo' />
                <div className='slideLeftBlockText'>Әр басқан қадамымда өзімнің және мені қоршаған ортаның қауіпсіздігін ойлаймын. Сондықтан кезекшілік кезінде ешқашан есірткі немесе алкогольді қолданбаймын.</div>
            </div>
        </div>
    );
}

export default CodexSlide5;