import React from 'react';
import './Eshcu.css';
import eshcuImg from '../../img/codexHeader.png';
import EshcuFirst from './EshcuFirst';
import EshcuSecond from './EshcuSecond';
import EshcuThird from './EshcuThird';
import EshcuFour from './EshcuFour';
import EshcuFive from './EshcuFive';
import EshcuSix from './EshcuSix';
import EshcuSeven from './EshcuSeven';
import NavBar from '../Navbar';
import EshcuSlider from './EshcuSlider';
import { NavLink } from 'react-router-dom';



const Eshcu = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container contentWrapper'>
                    <div className='codexHeaderWrapper contentBorder'>
                        <div className='codexLeftBlock'>
                            <img src={eshcuImg} alt='logo' />
                        </div>
                        <div className='codexRightBlock'>
                            <div className='codexSabak'>10 сабақтың 8-шісі</div>
                            <h1>Этикалық шешім қабылдау үлгілері</h1>
                            <h2>(Ұлттық) заңдар, жүріс-тұрыс ережелері мен ұйымдастыру кодексі үнемі адам құқығы саласындағы Конвенция мен Халықаралық құқық аясында әрекет етеді. </h2>
                        </div>
                    </div>
                    <EshcuFirst />
                    <EshcuSecond />
                    <EshcuThird />
                    <EshcuFour />
                    <EshcuFive />
                    <EshcuSix />
                    <EshcuSlider />
                    <EshcuSeven />
                    <button className='btnNext'><NavLink to='/Test'>Ары қарай</NavLink></button>
                </div>
            </div>
        </div>
    )
}

export default Eshcu;