import React from 'react';
import '../Scenario1/Scenario1.css';
import Scenario5Header from '../../img/Scenario5Header.jpg';
import Scenario5Video from './Scenario5Video';
import Scenario5Two from './Scenario5Two';
import Scenario5Video2 from './Scenario5Video2';
import Scenario5Four from './Scenario5Four';
import Scenario5Video3 from './Scenario5Video3';
import Scenario5Five from './Scenario5Five';
import NavBar from '../Navbar';
import Scenario5Video4 from './Scenario5Video4';
import { NavLink } from 'react-router-dom';

const Scenario5 = () => {
    return (
        <div>
            <NavBar />
            <div className='contentLeftPadding'>
                <div className='container contentWrapper'>
                    <div className='Scenario2Wrapper'>
                        <div className='Scenario2Img'>
                            <img src={Scenario5Header} alt='logo' />
                        </div>
                        <div className='Scenario2Content'>
                            <div className='lessonNumber'>10 сабақтың 6-шісі</div>
                            <h1>Адам құқығы, Сценарий 5</h1>
                            <div className='homeAnons'>
                                <h2>Жергілікті тұрғындар</h2>
                                <p>Патрульдік инспекторлар күзетілетін жерлерде (ерекше қорғалатын табиғи аумақтарда) отын жинап жатқан екі әйелді кездестіреді.
                            Әйелдер аймаққа отын жинауға рұқсаты бар жергілікті топқа жатады.</p>
                            </div>
                        </div>
                    </div>
                    <Scenario5Video />
                    <Scenario5Two />
                    <Scenario5Video2 />
                    <Scenario5Four />
                    <Scenario5Video3 />
                    <Scenario5Five />
                    <Scenario5Video4 />
                    <button className='btnNext'><NavLink to='/Codex'>Ары қарай</NavLink></button>
                </div>
            </div>
        </div >
    );
}

export default Scenario5;