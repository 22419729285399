import React from 'react';
import './Scenario1.css';
import ReactPlayer from 'react-player';

const Scenario1Video2 = () => {
    return (
        <div className='Scenario1VideoWrap'>
            <ReactPlayer url='https://youtu.be/GY6ske3FiZA' controls={true} />
        </div>
    );
}

export default Scenario1Video2;