import React from 'react';
import './Home.css';
import ReactPlayer from 'react-player';

const HomeVideo = () => {
    return (
        <div className='HomeVideo'>
            <ReactPlayer url='https://youtu.be/LiRM7lCqVGY' controls={true} />
        </div>
    );
}

export default HomeVideo;