import React from 'react';
import Scenario2FourAlert from '../../img/alert.jpg';


const Scenario3Seven = () => {
    return (
        <div className='Scenario1FourWrap'>
            <div className='Scenario1FourTitle'>Бұл сценарийде: Өмір сүру құқығы қарастырылады.</div>
            <div className='Scenario1FourAnons'>3-бап. Әрбір адам өмір сүруге, еркіндікке және жеке басына қол сұғылмауына құқылы.</div>
            <div className='Scenario1FourText'>Бұл құқық құқық қорғау органдарының қызметкерлері басқа адамдарға қарсы күш қолдану керек болған жағдайларға байланысты.
            Бұл сценарийде қандай да бір күш қолдануға себеп болған жоқ деуге болады. Сондай-ақ топтың жетекшісі ұрып-соғуы мен өлтіруін тоқтатпайды, оны сондай-ақ
            жауапты етеді. Егер ол инспекторлардың біреуіне күдіктіні ұрып-соғуды немесе өлтіруді бұйырса, инспектор да жауапкершілікке тартылатын еді, себебі бұйрық – заңсыз.
            <img src={Scenario2FourAlert} alt='logo' />
            </div>
            <div className='Scenario1FourPs'>Енді қайтадан сценарийді көріп, салыстырып қараңыз.</div>
        </div>
    );
}

export default Scenario3Seven;