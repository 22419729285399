export const Quiz = [
    {
        id: 0,
        question: `Төрт әріптесіңізбен күзетте жүрсіз. Қолына балта ұстап келе жатқан адамды көрдіңіздер. Сіздер күзететін аймақта ағаш шабуға тыйым  салынған. Сіз оған жақындаған кезде, ол қашқан жоқ. Ары қарай не істейсіз? (I-бөлім)`,
        options: [`Бөтен ойы жоқ екеніне көз жеткізу үшін, таяқпен ұрасыз.`, `Өзіңізді таныстырып, жұмысыңыз туралы айтып бересіз.`],
        answer: `Өзіңізді таныстырып, жұмысыңыз туралы айтып бересіз.`,
        right: `Алайда бұл Тұтқындалушы құқығында жазылмаған, бірақ бұл сөйлесіп тұрған адамыңызбен жақсы қарым-қатынас орнатуға, кім екеніңізді білуге мүмкіндік беретін жақсы әрі кәсіби тәжірибе.`,
        wrong: `Бұл АЗАПТАУДАН АЗАТ БОЛУ ҚҰҚЫҒЫ мен КҮШ ҚОЛДАНУ ҰСТАНЫМДАРЫН бұзу болып саналады. Азаптау немесе күшті орынсыз қолдану – адам құқығын бұзу болып табылады. Қарсыласпаған кезде таяқпен ұру – күшті орынсыз қолдану болып саналады, ал ол - заңсыз.`
    }, {
        id: 1,
        question: `Төрт әріптесіңізбен күзетте жүрсіз. Қолына балта ұстап келе жатқан адамды көрдіңіздер. Сіздер күзететін аймақта ағаш шабуға тыйым  салынған. Сіз оған жақындаған кезде, ол қашқан жоқ. Ары қарай не істейсіз(II-бөлім)`,
        options: [`Қорғалатын аймақта жүргені үшін оған тіл тигізесіз.`, `Қорғалатын аймақта не істеп жүргенін анықтау үшін сұрақтар қоясыз.`],
        answer: `Қорғалатын аймақта не істеп жүргенін анықтау үшін сұрақтар қоясыз.`,
        right: `Сіздерге біреуге сұрақ қоюға рұқсат етілген, бұл – жұмысыңыздың бір бөлігі. АЗАПТАУДАН АЗАТ БОЛУ ҚҰҚЫҒЫНДА түсіндірілетіндей, ақпарат алу үшін «қатігездік, адамгершілікке жатпайтын немесе ар-намысына тиетін қарым-қатынас» жасауға ешқашан рұқсат етілмейді`,
        wrong: `Тұтқын құқығында айыпталушға «оған тән адамгершілікпен, құрметпен әрі адамзатқа тән құрметпен» қарым-қатынас жасау керек екендігіңіз туралы айтылады. Біреуге тіл тигізу – оны  сыйламау болып табылады және сіз бен қауымдастық мүшелерімен араңыздағы қарым-қатынастың бұзылуына әкеліп соғады`
    }, {
        id: 2,
        question: `Бірнеше сұрақ қойғаннан кейін, әлгі адам ағаш шапқанын мойындайды. Не істейсіз? (1-бөлім)`,
        options: [`Оны тінтесіз.`, `Оның телефонын алып, барлық хабарламаларын оқып шығасыз және нөмірлерін тексеріп шығасыз.`],
        answer: `Оны тінтесіз.`,
        right: `Тұтқын құқығында «күдікті тұтқындалғанға дейін тінтілуі керек, кем дегенде бір куәнің көзінше және күдіктінің жынысындағы адам тінтуі керек» екені жазылады`,
        wrong: `егер сіз оның телефонын алып, ордерсіз хабарламаларын оқысаңыз, оның еркіндігі мен хат алмасуына құпиялылығын және мүлкіне қол сұғылмау құқығын бұзған болып саналасыз. Бұл дегеніміз тінтуге рұқсат беретін ордерсіз оның кез келген мүлкін тінте алмайсыз немесе хабарламаларын да, хаттарын да оқи алмайсыз деген сөз`
    }, {
        id: 3,
        question: `Бірнеше сұрақ қойғаннан кейін, әлгі адам ағаш шапқанын мойындайды. Не істейсіз? (2-бөлім)`,
        options: [`Оған тұтқындалғаны жайлы хабарлап, тұтқындалу себебін түсіндіресіз.`, `Егер ол шағымдана бастаса, теуіп жібересіз.`],
        answer: `Оған тұтқындалғаны жайлы хабарлап, тұтқындалу себебін түсіндіресіз.`,
        right: `Алайда бұл Тұтқындалушы құқығында жазылмаған, бірақ бұл сөйлесіп тұрған адамыңызбен жақсы қарым-қатынас орнатуға, кім екеніңізді білуге мүмкіндік беретін жақсы әрі кәсіби тәжірибе.`,
        wrong: `Бұл азаптаудан азат болу құқығын, тұтқындалушы құқығы мен күш қолдану ұстанымдарын бұзу болып саналады. Азаптау немесе күшті орынсыз қолдану – адам құқығын бұзу болып табылады.`
    }, {
        id: 4,
        question: `Сіз біреуді тұтқындап, инспекторлар лагеріне алып келген кезде, ол қашып кете жаздайды, бірақ оны әріптестеріңіз ұстап алады. Не істейсіз? (I-бөлім)`,
        options: [`Енді қайтып қашпауы үшін, ұрып-соғасыз.`, `Оны сотқа немесе сәйкес органға жіберу шараларын қолданасыз.`],
        answer: `Оны сотқа немесе сәйкес органға жіберу шараларын қолданасыз.`,
        right: `Тұтқын құқығына сәйкес «Тұтқындаудың жалғыз мақсаты – тұтқындалушыны заңды әрі әділ сот талқылауынан кейін соттай алатын сотқа барынша тез жеткізу». Сотқа дайындалу барысында, оның сот алдында тез арада болатынына көз жеткізуіңіз керек.`,
        wrong: `Бұл азаптаудан азат болу құқығын, тұтқндалушы құқығы мен күш қолдану ұстанымдарын бұзу болып саналады. Азаптау немесе күшті орынсыз қолдану – адам құқығын бұзу болып табылады. Егер күдікті бірігіп жұмыс жасаса, оның жанын ауыртуға себеп жоқ`
    }, {
        id: 5,
        question: `Сіз біреуді тұтқындап, инспекторлар лагеріне алып келген кезде, ол қашып кете жаздайды, бірақ оны әріптестеріңіз ұстап алады. Не істейсіз? (2I-бөлім)`,
        options: [`Ол инспекторлар лагерінде жатқан кезінде оның демалғанына, ас-суы бар екендігіне көз жеткізесіз.`, `Рұқсатсыз оның үйін тінтіп шығасыз.`],
        answer: `Ол инспекторлар лагерінде жатқан кезінде оның демалғанына, ас-суы бар екендігіне көз жеткізесіз.`,
        right: `тұтқындалушыға қамқорлық көрсете отырып, сіз тұтқындалушы құқықтарында талап етілгендей оған адамгершілік танытасыз`,
        wrong: `еркіндікке және хат алмасу құпиялылығына, мүлкіне қол сұғылмау құқығы ешкім басқа біреудің үйіне заңды рұқсатсыз басып кіре алмайды дегенді білдіреді.`
    }, {
        id: 6,
        question: `6-санатқа жататын кейбір ресурстарды пайдалануға рұқсат беретін аймақта жұмыс істейсіз.Жергілікті тұрғындардың балық аулауға рұқсаты бар, оған аттары жазылған балық аулауға берілген рұқсат қағаздары дәлел бола алады. Бір күні саяхатшылар біреулердің өзенде балық аулап жүргендерін айтады. Топтан бірнеше адамды алып, сол жерге барсаңыз, Жергілікті тұрғындардан 2 адам аумен балық аулап жүргенін көресіз. Бір ер адам және бір әйел адам. Не істейсіз?`,
        options: [`Оларға бұл жерде балық аулауға тыйым салынғанын және олардың браконьер екенін айтасыз.`, `Инспектор екеніңізді түсіндіріп, олардан балық аулауға рұқсат қағаздарын сұрайсыз.`],
        answer: `Инспектор екеніңізді түсіндіріп, олардан балық аулауға рұқсат қағаздарын сұрайсыз.`,
        right: `Алайда бұл Тұтқындалушы құқығында жазылмаған, бірақ бұл сөйлесіп тұрған адамыңызбен жақсы қарым-қатынас орнатуға, кім екеніңізді білуге мүмкіндік беретін жақсы әрі кәсіби тәжірибе.`,
        wrong: `Алайда бұл Тұтқындалушы құқығында жазылмаған, бірақ бұл сөйлесіп тұрған адамыңызбен жақсы қарым-қатынас орнатуға, кім екеніңізді білуге мүмкіндік беретін жақсы әрі кәсіби тәжірибе`
    }, {
        id: 7,
        question: `Ер адам рұқсат қағазын көрсетеді, ал әйел өзінікін үйде ұмытып кеткенін айтады`,
        options: [`Екеуін де тұтқындап, рұқсатсыз балық аулауға тыйым салынғанын түсіндіресіз. `, `Тек қана әйелді тұтқындайсыз да, рұқсатсыз балық аулауға тыйым салынғанын түсіндіресіз.`],
        answer: `Тек қана әйелді тұтқындайсыз да, рұқсатсыз балық аулауға тыйым салынғанын түсіндіресіз.`,
        right: `Әйел рұқсатсыз балық аулап, заңсыз әрекет жасады. Жергілікті заңға қарай оны тұтқындауға немесе айыппұл салуға құқығыңыз бар.`,
        wrong: `адам еркіндігі мен қауіпсіздігі құқығын бұзасыз, өйткені сіз адамды заңсыз негізде тұтқындап жатырсыз. Ол жаман ештеңе жасаған жоқ, балық аулауға рұқсаты болды`
    }, {
        id: 8,
        question: `Әйелді тұтқындап, лагерьге алып келесіз де, ары қарай не істейсіз? (1-бөлім)`,
        options: [`Әріптес-әйелге оны тінтуін өтінесіз.`, `Оның қайда тұратынын біліп, үйін тінтуге кетесіз, әйелдің өзін инспекторлар лагерінде қалдырасыз.`],
        answer: `Әріптес-әйелге оны тінтуін өтінесіз.`,
        right: `тұтқындалушының жынысына сәйкес адамға күдіктіні тінтуді өтінуіңіз – оны сыйлап, адамгершілік танытқаныңыз болады.`,
        wrong: `оның еркіндігі мен хат алмасуына құпиялығын және мүлкіне қол сұғылмау құқығын бұзған болып саналасыз. Бұл дегеніміз тінтуге рұқсат беретін ордерсіз оның кез келген мүлкін тінте алмайсыз немесе хабарламалырын да, хаттарын да оқи алмайсыз деген сөз`
    }, {
        id: 9,
        question: `Әйелді ғана тұтқындап, лагерьге алып келесіз де, ары қарай не сітейсіз? (2-бөлім)`,
        options: [`Командирге хабарлап, сотқа қажетті барлық қажетті шараларды дайындайсыз.`, `Оны тәртібі үшін жаза ретінде екі тәулікке бөлмеге қамап тастайсыз.`],
        answer: `Командирге хабарлап, сотқа қажетті барлық қажетті шараларды дайындайсыз.`,
        right: `Тұтқын құқығына сәйкес «Тұтқындаудың жалғыз мақсаты – тұтқындалушыны заңды әрі әділ сот талқылауынан кейін соттай алатын сотқа барынша тез жеткізу». Сотқа дайындалу барысында, оның сот алдында тез арада болатынына көз жеткізуіңіз керек.`,
        wrong: `Бұл әрекет адал сот үкімі құқығын бұзады. Қаншалықты жаман болса да, барлығы түгелдей әділ сот талқылауына құқылы, әрі оны басқа азамат емес, үнемі заңмен бектілген сот жасауы керек. Оны жазалау мақсатында бөлмеге қамай отырып, сіз заңсыз сот рөлін иеленесіз. Сонымен қатар бұл оның АЗАПТАУДАН АЗАТ БОЛУ ҚҰҚЫҒЫН бұзатын азаптау формасы болып табылады, өйткені сіз оны жасаған әрекеті үшін жазалайсыз.`
    }, {
        id: 10,
        question: `Сегіз әріптесіңізбен өздеріңіз қорғайтын аймақты қарумен патрульдеуде жүрсіз делік. Көптен болмаған аймақта патрульдеп жүрсіздер. Аяқ астынан тапаншаның атылған дауысын естисіз де, инспекторлардың біреуі жараланғанын көресіз. Браконьердің тосқауылына түсіп қаласыздар. Браконьерлер атып жатқанда, жасырынатын жер тауып аласыздар, ары қарай не істейсіз?`,
        options: [`Мүмкін болса, мен де оқ жаудыруды бастаймын, өйткені бұл оқиға өмірге қауіпті.`, `Мен атпаймын, өйткені бұл заңға қайшы.`],
        answer: `Мүмкін болса, мен де оқ жаудыруды бастаймын, өйткені бұл оқиға өмірге қауіпті.`,
        right: `«Өмір сүру құқығы» мен «Күш қолдану» ұстанымдарына сәйкес егер сізге өзіңіздің немесе біреудің өмірін өлімнен немесе айтарлықтай жарақат алудан қорғау үшін тек қана біреуді тоқтату (тоқтату үшін ату) мақсатында қару-жарақ қолдана аласыз.`,
        wrong: `«Өмір сүру құқығы» мен «Күш қолдану» ұстанымдарына сәйкес егер сізге өзіңіздің немесе біреудің өмірін өлімнен немесе айтарлықтай жарақат алудан қорғау үшін тек қана біреуді тоқтату (тоқтату үшін ату) мақсатында қару-жарақ қолдана аласыз.`
    }, {
        id: 11,
        question: `Аздаған атыстан соң, браконьерлер жақ тынышталып қалды. Топ мүшелері жараланған инспекторға қамқор болып, жақсылап тергеуге кетеді. Сөйтсеңіз, барлық браконьер, жараланған браконьерді жалғыз тастап, өздері қашып кеткен екен. Оның иығы жараланыпты. Командиріңіз оны атуға бұйрық береді. Не істейсіз?`,
        options: [`Мен де әрине оны атамын, өйткені ол менің әріптесімді жаралап, маған да оқ атты ғой.`, `Атудан бас тартасыз, өйткені ол - заңсыз.`],
        answer: `Атудан бас тартасыз, өйткені ол - заңсыз.`,
        right: `Командиріңізге қарсы шығу оңай емес, бірақ бұл жалғыз заңды жол. Бұлай жасай отырып, сіз адам құқығын, тұтқындалушы құқығы мен әділ сот талқысына құқығын қорғайсыз, Сонымен қатар сіз Күш қолдану Ұстанымдарын сақтайсыз.`,
        wrong: `Бұл жөнсіз адам өлтіру болып саналады және өмір сүру құқығын, Күш қолдану ұстанымдарын, Тұтқын құқығын бұзу болып табылады. Бұған қатаң тыйым салынған және арты қиын болады.`
    }, {
        id: 12,
        question: `Біраз талқылаған соң, командиріңізді райынан қайтарасыз, браконьер жалғыз қалады. Ол қатты қансырап жатады. Не істейсіз? (1-бөлім)`,
        options: [`Браконьерге алғашқы көмек көрсетіп, оны ауруханаға апарасыз.`, `Браконьердібас басқармаға апарып, ауруханаға жібермейсіз.`],
        answer: `Браконьерге алғашқы көмек көрсетіп, оны ауруханаға апарасыз.`,
        right: `бұл сіздің тұтқындалушыңыз. Тұтқындалушы құқығы оған медициналық көмек, ас-суын, керек болған жағдайда қанды қамтамасыз етіп, оған адамгершілікпен және сый-құрметпен қарау керек екеніңізді айтады.`,
        wrong: `бұл сіздің тұтқындалушыңыз. Тұтқындалушы құқығы оған медициналық көмек, ас-суын, керек болған жағдайда қанды қамтамасыз етіп, оған адамгершілікпен және сый-құрметпен қарау керек екеніңізді айтады.`
    }, {
        id: 13,
        question: `Біраз талқылаған соң, командиріңізді райынан қайтарасыз, браконьер жалғыз қалады. Ол қатты қансырап жатады. Не істейсіз? (2-бөлім)`,
        options: [`Жарасынан басып, ауырта отырып, одан ақпарат алуға тырысасыз.`, `Оны тұтқындап, не үшін екенін түсіндіресіз.`],
        answer: `Оны тұтқындап, не үшін екенін түсіндіресіз.`,
        right: `Алайда бұл Тұтқындалушы құқығында жазылмаған, бірақ бұл сөйлесіп тұрған адамыңызбен жақсы қарым-қатынас орнатуға, кім екеніңізді білуге мүмкіндік беретін жақсы әрі кәсіби тәжірибе`,
        wrong: `Бұл Азаптаудан азат болу құқығында анықталғандай – азаптау. Адам жанына немесе санасына ауыру сезімі мен зардап тудыратын кез келген әдейі жасалған әрекет – азаптау болып саналады. Көбінесе ақпарат алу мақсатында азаптау қолданылады. Бұл қандай жағдай болмасын ақталмайды.`
    }, {
        id: 14,
        question: `Браконьерді ауруханаға апарады, күткендей ол жазылып келе жатады. Инспекторлар лагеріне келген соң, командиріңіздің заңсыз бұйрығымен бірдеңе істеу керек болады. Не істейсіз? (I-бөлім)`,
        options: [`Біраз ойланған соң,ештеңе істемеуге шешім қабылдаймын, өйткені ол маған сосын көрсетеді.`, `Оның бастығына жалғыз барып, болған оқиғаны айтамын.`],
        answer: `Оның бастығына жалғыз барып, болған оқиғаны айтамын.`,
        right: `бұл - болған оқиғадан шығатын және тобыңыздың кәсіби әрекет етуіне ықпал ететін жалғыз тәсіл. Командиріңіздің бастығы сіз жеткізген ақпараттан кейін тергеу жүргізуі керек. Болған оқиғаны растайтын куәгеріңіз болғанда жақсы болатын еді.`,
        wrong: `Командиріңіздің бұйрығы заңсыз болатын және өз еркімен адам өлтіруге әкеліп соғатын еді. Бұл өте маңызды қылмыс және ол туралы хабарлауыңыз керек, сонда топ мүшелері заң жүзінде әрекет ететін болады. Егер сіз ол туралы айтпасаңыз, қылмыстың бір бөлігі болап табыласыз.`
    }, {
        id: 15,
        question: `Браконьерді ауруханаға апарады, күткендей ол жазылып келе жатады. Инспекторлар лагеріне келген соң, командиріңіздің заңсыз бұйрығымен бірдеңе істеу керек болады. Не істейсіз? (2-бөлім)`,
        options: [`Ештеңе болмағандай жүре беремін, бұдан былай ол мұндай қателікке жол бермейді деп үміттенемін.`, `Оның бастығына басқа да инспекторлармен бірге барып, болған оқиғаға дәлел келтіремін.`],
        answer: `Оның бастығына басқа да инспекторлармен бірге барып, болған оқиғаға дәлел келтіремін.`,
        right: `бұл жағдайдан шығатын және топтың кәсіби әрекет етуіне ықпал ететін жалғыз тәсіл – әріптестеріңізбен бірге, мүмкін болса, куәгерлермен бірге барып, командиріңіздің әрекеті жайлы хабарлау, бұл сіздің жеке басыңыз үшін төніп тұрған қауіпті азайтып, сәтті тергеуге мүмкіндікті арттырады.`,
        wrong: `бұл жағдайдан шығатын және топтың кәсіби әрекет етуіне ықпал ететін жалғыз тәсіл – әріптестеріңізбен бірге, мүмкін болса, куәгерлермен бірге барып, командиріңіздің әрекеті жайлы хабарлау, бұл сіздің жеке басыңыз үшін төніп тұрған қауіпті азайтып, сәтті тергеуге мүмкіндікті арттырады.`
    }
]